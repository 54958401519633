@import "./rangeslider";

body {
  opacity: 1;
  transition: opacity 0.5s;
  &.notready {
    opacity: 0;
  }
}

.relative {
  position: relative;
}

body.quick-setup {
  padding-top: 30px;
}
.anchor,
.scrollnav-anchor {
  display: block;
  position: absolute;
  height: 0px;
  overflow: hidden;
}
.body-navbar-fixed {
  padding-top: 50px;
  .anchor {
    top: -50px;
  }
}

/* help bootstrap */
.alert-hidden {
  display: none;
}
.navbar-brand-simplified {
  height: auto;
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-t-10 {
  margin-top: 10px;
}
.m-t-15 {
  margin-top: 15px;
}
.nav {
  position: relative;
  @media (max-width: 767px) {
    .separate-after {
      margin-bottom: 10px;
    }
    .separate-after:after {
      content: "";
      position: absolute;
      left: 5px;
      right: 5px;
      bottom: -5px;
      border-bottom: 1px solid #333;
    }
  }
  @media (min-width: 768px) {
    .separate-after {
      margin-right: 10px;
    }
    .separate-after:after {
      content: "";
      position: absolute;
      top: 2px;
      bottom: 2px;
      right: -5px;
      border-right: 1px solid #333;
    }
  }
}
.navbar-nav > li button {
  padding: 10px 15px;
  margin-left: 5px;
  margin-right: 5px;
}
@media (max-width: 767px) {
  .navbar-nav > li button {
    margin-left: 15px;
    margin-right: 15px;
  }
}
@media (min-width: 768px) {
  .navbar-nav > li button {
    margin-top: 5px;
  }
}
.btn-transparent {
  background-color: transparent;
  background-image: none;
  &:active,
  &.active {
    box-shadow: none;
  }
}

$iosoffsettop: 30px;

/* common */
.main-top-navbar {
  position: relative;
  z-index: 100;
}
html.ios {
  .body-navbar-fixed {
    padding-top: 50px + $iosoffsettop;
  }
  .main-top-navbar {
    padding-top: $iosoffsettop;
  }
  .modal-dialog {
    margin-top: 30px;
  }
  .body-navbar-fixed .navbar-fixed-top {
    padding-top: $iosoffsettop;
  }
  @media (min-height: 630px) and (max-width: 400px) {
    body.quick-setup {
      padding-top: 40px + $iosoffsettop;
    }
    .body-navbar-fixed {
	    padding-top: 40px + $iosoffsettop;
      .anchor {
        top: -65px - $iosoffsettop;
      }
    }
    .main-top-navbar {
      padding-top: 10px + $iosoffsettop;
    }
    .modal-dialog {
      margin-top: 100px;
    }
  }
  .hide-for-non-ios {
    display: block;
  }
  .hide-for-ios {
    display: none;
  }
}
html.cordova {
  .hide-for-non-cordova {
    display: block;
  }
  .hide-for-cordova {
    display: none;
  }
}
html.cordova.ios {
  .hide-for-non-cordova-ios {
    display: block;
  }
  .hide-for-cordova-ios {
    display: none;
  }
}
html {
  .hide-for-non-cordova-ios {
    display: none;
  }
  .hide-for-cordova-ios {
    display: block;
  }
  .hide-for-non-ios {
    display: none;
  }
  .hide-for-ios {
    display: block;
  }
  .hide-for-non-cordova {
    display: none;
  }
  .hide-for-cordova {
    display: block;
  }
}

.fit-td {
  width: 20px;
}

.x-collapsible {
  display: block; 
  position: relative;
  overflow: hidden;
  height: auto; /* unknown depending on content */
  transition: height 0.5s;
  .x-collapsible-inner {
    display: inline-block;
  }
  &.x-collapse {
    height: 0px !important;
    display: block;
  }
}
.x-navbar {
  .head {
    h1, h2 {
      color: #777;
      margin: 0;
      padding: 5px;
      font-family: "Source Sans Pro", Arial, sans-serif;
      font-weight: 700;
      font-size: 1.9em;
      line-height: 1.2;
    }
    h2 {
      font-size: 1.7em;
    }
  }
  padding: 0;
  margin: 0;
  padding-left: 15px;
  padding-right: 15px;
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 50px;
  .icon-btn {
    margin-left: 8px;
    margin-right: 8px;
    padding-left: 6px;
    padding-right: 6px;
    display: inline-block;
    &:hover {
      text-decoration: none;
    }
  }
  .disabled {
    color: #777;
    cursor: default;
  }
  > .space {
    flex-grow: 1;
  }
}


@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/sourcesanspro-bold-webfont.woff2') format('woff2'),
  url('../fonts/sourcesanspro-bold-webfont.woff') format('woff'),
  url('../fonts/sourcesanspro-bold-webfont.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@media (max-width: 767px) { /* bootstrap xs < */
  .m-xs-t-10 {
    margin-top: 10px;
  }
}

.video-iframe-wrp {
  text-align: center;
}

.video-iframe {
  border: none;
  max-width: 95%;
}

.select-btn-wrp {
  display: flex;
  flex-direction: row;
  .form-control {
    flex: 0px 1 1;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .btn {
    margin-left: 5px;
    height: 34px;
  }
}

.x-horiz-group {
  display: flex;
  flex-direction: row;
  & > * {
    margin-right: 10px;
  }
}

.icon-with-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  .text {
    margin-left: 0.3em;
    font-size: 1.1em;
  }
}

#move-top-link {
  position: fixed;
  right: 5px;
  bottom: 5px;
}


.fadein {
  opacity: 1 !important;
  transition: opacity 0.3s;
}
.fadeout {
  opacity: 0 !important;
  transition: opacity 0.3s;
}

.non-selectable { // by default no select
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                        supported by Chrome and Opera */
}
.selectable {
  -webkit-touch-callout: auto; /* iOS Safari */
  -webkit-user-select: auto; /* Safari */
  -khtml-user-select: auto; /* Konqueror HTML */
  -moz-user-select: auto; /* Firefox */
  -ms-user-select: auto; /* Internet Explorer/Edge */
  user-select: auto; /* Non-prefixed version, currently
                        supported by Chrome and Opera */
}
