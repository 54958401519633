html.ios {
  .node-setting {
    display: block;
  }
}
.node-setting {
  display: none;
}
.node-record-btn-wrap {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  display: inline-block;
  position: relative;
  &.recording {
    .record-amp-circle {
      display: block;
    }
  }
  .record-amp-circle {
    display: none;
    z-index: 1;
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: rgba(240, 40, 50, 0.65);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
