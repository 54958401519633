/* LIGHT */
$theme-color-dimmed: #777;

$theme-light-bg: #C8F2FD;
$theme-light-primary: #001401;
$theme-light-secondary: #D6602B;
$theme-light-hint: #F0C000;
$theme-light-secondary-bg: #FFF;
$theme-light-parent-arrow: red;
$theme-light-child-arrow: #af473d;

$theme-lightbold-bg: #C8F2FD;
$theme-lightbold-primary: #001401;
$theme-lightbold-secondary: #D6602B;
$theme-lightbold-hint: #F0C000;
$theme-lightbold-secondary-bg: #FFF;
$theme-lightbold-parent-arrow: red;
$theme-lightbold-child-arrow: #C8F2FD;

$theme-greyred-bg: #F4F4F4;
$theme-greyred-primary: #FF4136;
$theme-greyred-secondary: #000000;
$theme-greyred-hint: #AAAAAA;
$theme-greyred-secondary-bg: #FFF;
$theme-greyred-parent-arrow: red;
$theme-greyred-child-arrow: #F4F4F4;


$theme-yb-bg: #FFFF00;
$theme-yb-primary: #999999;
$theme-yb-secondary: #000000;
$theme-yb-hint: #AAAAAA;
$theme-yb-secondary-bg: #ddd342;
$theme-yb-parent-arrow: red;
$theme-yb-child-arrow:  #999999;


$theme-by-bg: #000000;
$theme-by-primary: #FBF1A9;
$theme-by-secondary: #FFFF00;
$theme-by-hint: #FBF1A9;
$theme-by-secondary-bg: #636363;
$theme-by-parent-arrow: red;
$theme-by-child-arrow: #FBF1A9;

$theme-mint-bg: #9EEBCF;
$theme-mint-primary: #999999;
$theme-mint-secondary: #000000;
$theme-mint-hint: #AAAAAA;
$theme-mint-secondary-bg: #70c9a8;
$theme-mint-parent-arrow: red;
$theme-mint-child-arrow: #999999;

$theme-dark-bg: #12003D;
$theme-dark-primary: #FFE400;
$theme-dark-secondary: #D6602B;
$theme-dark-hint: #EEE;
$theme-dark-secondary-bg: #544b68;
$theme-dark-parent-arrow: red;
$theme-dark-child-arrow: #FFE400;

#tree {
  .content {
    opacity: 0.5;
    transition: color, opacity 0.5s;
  }
  .active > .content,
  .content.selected,
  .content.highlight {
    opacity: 1;
  }
}

body.theme-light {
  background-color: $theme-light-bg;
  color: $theme-light-primary;
  #tree ul.children {
    background-color: $theme-light-bg;
    .highlight {
      color: $theme-light-secondary;
    }
    .selected {
      color: $theme-light-hint;
    }
  }
  #message-bar-wrp {
    background-color: $theme-light-secondary-bg;
  }
  #message-bar-close-btn {
    color: $theme-color-dimmed;
  }
  #message-bar {
    color: $theme-light-secondary;
  }
  #tree .current .content > .arrow-wrp { color: $theme-light-child-arrow; font-size: 120%;   transform: rotate(-180deg);}
  #tree .current > .content > .arrow-wrp { color: $theme-light-parent-arrow;transform: none;}
}

body.theme-lightbold {
  background-color: $theme-light-bg;
  color: $theme-light-primary;
  font-weight: bold;
  #tree ul.children {
    background-color: $theme-light-bg;
    .highlight {
      color: $theme-light-secondary;
    }
    .selected {
      color: $theme-light-hint;
    }
  }
  #message-bar-wrp {
    background-color: $theme-lightbold-secondary-bg;
  }
  #message-bar-close-btn {
    color: $theme-color-dimmed;
  }
  #message-bar {
    color: $theme-lightbold-secondary;
  }
  #tree .current .content > .arrow-wrp { color: $theme-lightbold-child-arrow; font-size: 120%;transform: rotate(-180deg);}
  #tree .current > .content > .arrow-wrp { color: $theme-lightbold-parent-arrow;transform: none; }
}

body.theme-greyred {
  background-color: $theme-greyred-bg;
  color: $theme-greyred-primary;
  #tree ul.children {
    background-color: $theme-greyred-bg;
    .highlight {
      color: $theme-greyred-secondary;
    }
    .selected {
      color: $theme-greyred-hint;
    }
  }
  #message-bar-wrp {
    background-color: $theme-greyred-secondary-bg;
  }
  #message-bar-close-btn {
    color: $theme-color-dimmed;
  }
  #message-bar {
    color: $theme-greyred-secondary;
  }
  #tree .current .content > .arrow-wrp { color: $theme-greyred-child-arrow; font-size: 120%;transform: rotate(-180deg);}
  #tree .current > .content > .arrow-wrp { color: $theme-greyred-parent-arrow; transform: none;}
}

body.theme-yb {
  background-color: $theme-yb-bg;
  color: $theme-yb-primary;
  font-weight: bold;
  #tree ul.children {
    background-color: $theme-yb-bg;
    .highlight {
      color: $theme-yb-secondary;
    }
    .selected {
      color: $theme-yb-hint;
    }
  }
  #message-bar-wrp {
    background-color: $theme-yb-secondary-bg;
  }
  #message-bar-close-btn {
    color: $theme-color-dimmed;
  }
  #message-bar {
    color: $theme-yb-secondary;
  }
  #tree .current .content > .arrow-wrp { color: $theme-yb-child-arrow; font-size: 120%;transform: rotate(-180deg);}
  #tree .current > .content > .arrow-wrp { color: $theme-yb-parent-arrow; transform: none;}

}

body.theme-by {
  background-color: $theme-by-bg;
  color: $theme-by-primary;
  font-weight: bold;
  #tree ul.children {
    background-color: $theme-by-bg;
    .highlight {
      color: $theme-by-secondary;
    }
    .selected {
      color: $theme-by-hint;
    }
  }
  #message-bar-wrp {
    background-color: $theme-by-secondary-bg;
  }
  #message-bar-close-btn {
    color: $theme-color-dimmed;
  }
  #message-bar {
    color: $theme-by-secondary;
  }
  #tree .current .content > .arrow-wrp { color: $theme-by-child-arrow; font-size: 120%;transform: rotate(-180deg);}
  #tree .current > .content > .arrow-wrp { color: $theme-by-parent-arrow; transform: none; }

}

body.theme-mint {
  background-color: $theme-mint-bg;
  color: $theme-mint-primary;
  #tree ul.children {
    background-color: $theme-mint-bg;
    .highlight {
      color: $theme-mint-secondary;
    }
    .selected {
      color: $theme-mint-hint;
    }
  }
  #message-bar-wrp {
    background-color: $theme-mint-secondary-bg;
  }
  #message-bar-close-btn {
    color: $theme-color-dimmed;
  }
  #message-bar {
    color: $theme-mint-secondary;
  }
  #tree .current .content > .arrow-wrp { color: $theme-mint-child-arrow; font-size: 120%;transform: rotate(-180deg);}
  #tree .current > .content > .arrow-wrp { color: $theme-mint-parent-arrow;transform: none; }

}

body.theme-dark {
  background-color: $theme-dark-bg;
  color: $theme-dark-primary;
  #tree ul.children {
    background-color: $theme-dark-bg;
    .highlight {
      color: $theme-dark-secondary;
    }
    .selected {
      color: $theme-dark-hint;
    }
  }
  #popup-message-wrp {
    color: $theme-dark-hint;
    background-color: rgba(255, 255, 255, 0.4);
    #popup-message {
      background-color: $theme-dark-bg;
    }
  }
  #message-bar-wrp {
    background-color: $theme-dark-secondary-bg;
  }
  #message-bar-close-btn {
    color: $theme-color-dimmed;
  }
  #message-bar {
    color: $theme-dark-secondary;
  }
  #tree .current .content > .arrow-wrp { color: $theme-dark-child-arrow; font-size: 120%;transform: rotate(-180deg);}
  #tree .current > .content > .arrow-wrp { color: $theme-dark-parent-arrow; transform: none;}
  
}
