@import "~bootstrap/dist/css/bootstrap.css";

@import "./common";
@import "./edit-config";
@import "./editor";
@import "./themes";
@import "./debug-tools";

$btn-default-color:     #333;
$btn-danger-bg:          #d9534f;
$btn-success-bg:         #5cb85c;

html.pasco-main.ios,
html.pasco-main.ios body {
  padding: 0;
  margin: 0;
  height: 100vh;
  overflow: hidden;
  position: relative;
}
html.pasco-main.ios {
  #navbtns-wrp {
    top: 74px;
  }
  @media (min-height: 630px) and (max-width: 400px) {
    #navbtns-wrp {
      top: 89px;
    }
  }
  #popup-message-wrp {
    top: 70px;
  }
}
// fake scrolls
html.pasco-main.has-fake-scroll {
  .main-top-navbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
  }
  #tree-wrp {
    position: fixed;
  }
  .fake-scroll-elm {
    position: absolute;
    left: 0px;
    top: 0px;
    right: -120px;
    bottom: -120px;
  }
}

.pasco-main {
  #theinput-wrp {
    width: 1px;
    height: 1px; 
    opacity: 0;
    position: absolute;
    top: 50px;
    left: 50px;
    z-index: -10;
    overflow: hidden;
  }
  #theinput {
    width: 50px;
    height: 20px;
    opacity: 0;
    z-index: -10;
    font-size: 0.1px;
  }

  /* TREE STYLING */

  .highlight {
    color: darkorange;
  }
  .selected {
    color: lightblue;
  }
  #tree-wrp {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  body.rtl #tree {
    & > div.children-wrp {
      left: initial;
      right: 0;
    }
    .children-wrp {
      left: initial;
      right: 100%;
    }
    .content {
      & > .arrow-wrp {
        .fa-angle-left {
          display: inline-block;// only for rtl
        }
        .fa-angle-right {
          display: none;// hide for rtl
        }
      }
    }
  }
  #tree {
    position: relative;
    margin-left: 0px;
    margin-right: 0px;
    flex-grow: 1;
    z-index: 0;
    overflow: hidden;
    transition: margin-left 0.3s, margin-right 0.3s;
    @media (max-width: 767px) {
      ul.children {
        width: calc(100vw - 48px);
      }
    }
    &.no-transition {
      transition: none;
    }
    .content > .arrow-wrp {
      transition: opacity 0.5s;
      opacity: 0;
    }
    &.current .content,
    .current .content,
    .active > .content {
      & > .arrow-wrp {
        opacity: 1;
      }
    }
    &.active > .children-wrp,
    .active > .children-wrp {
      display: block;
    }
    &.edit-mode ul.children {
      padding-left: 30px;
      padding-right: 30px;
    }
    ul.children {
      display: flex;
      flex-direction: column;
      list-style: none;
      padding: 0px;
      margin: 0px;
      padding-left: 15px;
      padding-right: 15px;
      box-sizing: border-box;
      position: absolute;
      background-color: white;
      transition: top 0.3s;
    }
    &.current ul.children,
    .current ul.children {
      max-width: 100vw;
    }
    .children-wrp {
      display: none;
      position: absolute;
      left: 100%;
      top: 0;
      width: 100vw; // relax ul.children
    }
    & > div.children-wrp {
      left: 0;
    }
    .no-transition > .children-wrp > ul.children {
      transition: none;
    }
    .active > .content,
    .content.highlight,
    .content.selected {
      padding: 30px 0px;
      // padding: 2.14em 0.71em;
    }
    .text {
      display: block;
      padding: 15px 10px;
      // padding: 1.07em 0.71em;
      margin: 0px;
      font-size: 1.7em;
      line-height: 1.3;
    }
    .content {
      position: relative;
      z-index: 1;
      display: flex;
      padding: 0px;
      transition: padding 0.5s;
      & > .text {
        flex-grow: 1;
      }
      & > .arrow-wrp {
        margin-left: 5px;
        position: relative;
        .fa-angle-left {
          display: none;// only for rtl
        }
        .fa, .far {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    .node.selected .content {
      z-index: 3;
    }
    .node-edit-overlay {
      position: absolute;
      z-index: 3;
      left: -40px;
      right: -40px;
      top: -40px;
      bottom: -40px;
      background-color: rgba(255, 255, 255, .5);
      border-radius: 5px;
      .text-input-wrp {
        position: absolute;
        left: 40px;
        top: 40px;
        right: 40px;
        bottom: 40px;
        .text-input {
          display: block;
          width: 100%;
          height: 100%;
          border: none;
          font-size: 1.7em;
          line-height: 1.3;
        }
      }
      .add-node-before,
      .add-node-after,
      .add-child-node,
      .remove-node,
      .node-setting {
        position: absolute;
        z-index: 1;
      }
      .add-node-before {
        top: 0px;
        left: 50%;
        transform: translate(-50%, 0);
      }
      .add-node-after {
        bottom: 0px;
        left: 50%;
        transform: translate(-50%, 0);
      }
      .add-child-node {
        top: 50%;
        right: 0px;
        transform: translate(0, -50%);
      }
      .remove-node {
        top: 50%;
        left: 0px;
        transform: translate(0, -50%);
        color: $btn-danger-bg;
      }
      .node-setting {
        color: $btn-default-color;
        top: 0px;
        right: 0px;
      }
      .add-node-before,
      .add-node-after,
      .add-child-node {
        color: $btn-success-bg;
      }
    }

    &.edit-mode {
      ul.children {
        max-width: calc(#{"100vw - 80px"});
      }
      &.current > .children-wrp > ul.children,
      .current > .children-wrp > ul.children {
        padding: 40px;
      }
      .node {
        cursor: pointer;
      }
    }
    & > .content { // do not display content at level zero
      display: none;
    }
  }

  body:not(.debug-mode) .on-debug-mode {
    display: none;
  }
  body.debug-mode .on-debug-mode {
    display: block;
  }

  /** Navigation Buttons **/

  #navbtns-wrp {
    z-index: 5;
    position: fixed;
    top: 54px;
    right: 10px;
    display: none;
    &.navbtns-enable {
      display: block;
    }
    .edit-bound {
      position: absolute;
      top: -2px;
      left: -2px;
      right: -2px;
      bottom: -2px;
      border: 3px dotted darkgrey;
      .reset-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @media (max-width: 450px), (max-height: 450px) {
          padding: 3px 6px;
          font-size: 90%;
        }
      }
      .resize-tr-hdl .dot, .resize-tl-hdl .dot,
      .resize-br-hdl .dot, .resize-bl-hdl .dot {
        display: block;
        width: 10px;
        height: 10px;
        background-color: white;
        border: 2px solid black;
      }
      .resize-tr-hdl, .resize-tl-hdl,
      .resize-br-hdl, .resize-bl-hdl {
        position: absolute;
        z-index: 2;
        cursor: pointer;
        padding: 15px;  
      }
      .resize-tr-hdl {
        right: 0px;
        top: 0px;
        transform: translate(50%, -50%);
      }
      .resize-tl-hdl {
        left: 0px;
        top: 0px;
        transform: translate(-50%, -50%);
      }
      .resize-br-hdl {
        right: 0px;
        bottom: 0px;
        transform: translate(50%, 50%);
      }
      .resize-bl-hdl {
        left: 0px;
        bottom: 0px;
        transform: translate(-50%, 50%);
      }
    }
  }
  #navbtns {
    width: auto;
    height: auto;
    max-width: 40vw;
    max-height: 40vh;
    #nav-leftbtn, #nav-topbtn, #nav-rightbtn, #nav-bottombtn {
      cursor: pointer;
    }
    @media (max-width: 767px) {
      & {
        max-width: 150px;
        max-height: 150px;
        max-width: 40vw;
        max-height: 40vh;
      }
    }
    .main-stroke-outline {
      stroke: #606060 !important;
      stroke-width: 20 !important;
      cursor: grab;
    }
    .stroke-outline {
      stroke: #606060 !important;
      stroke-width: 15 !important;
    }
  }

  /* popup message */
  #popup-message-wrp {
    &.visible {
      opacity: 1;
    }
    opacity: 0;
    transition: opacity 0.5s;
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 4;
    color: black;
    background-color: rgba(0, 0, 0, 0.4);
    #popup-message {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 15px;
      overflow: hidden;
      overflow-y: auto;
      width: 80%;
      @media (min-width: 468px) {
        & {
          width: 400px
        }
      }
      max-height: 80%;
      background-color: white;
      border-radius: 3px;
    }
    .main-text {
      text-align: center;
      font-size: 1.7em;
      line-height: 1.3;
    }
  }

  .on-debug-mode {
    margin-top: 6px;
  }
  @media (max-width: 250px) {
    #edit-mode-btn, #edit-mode-save-btn, #edit-mode-cancel-btn {
      display: none;
    }
  }

  #message-bar-wrp {
    position: relative;
    padding: 1em;
    background-color: white;
    z-index: 1;
    box-sizing: border-box;
    min-height: 4em;
    #message-bar-close-btn {
      position: absolute;
      top: 0.5em;
      right: 0.5em;
      font-size: 130%;
      color: #555;
    }
  }
  #message-bar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .text {
      margin: 0px;
      font-size: 1.7em;
      line-height: 1.3;
      margin-left: 0.5em;
      margin-right: 0.5em;
    }
  }
}
